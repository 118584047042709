
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ACheckbox",

  props: {
    trueValue: {
      type: [String, Boolean, Object] as PropType<
        string | boolean | Record<string, string>
      >,
      default: "1",
    },
    falseValue: {
      type: [String, Boolean, Object] as PropType<
        string | boolean | Record<string, string>
      >,
      default: "0",
    },
    label: { type: String as PropType<string>, default: "" },
    trueLabel: { type: String as PropType<string>, default: "" },
    falseLabel: { type: String as PropType<string>, default: "" },
    disabled: { type: Boolean as PropType<boolean>, default: false },
    hideMarginTop: { type: Boolean as PropType<boolean>, default: false },
    indeterminate: {
      type: Boolean as PropType<boolean | undefined>,
      default: undefined,
    },
    value: {
      type: [String, Boolean, Object] as PropType<
        string | boolean | Record<string, string>
      >,
      default: "",
    },
  },

  computed: {
    cLabel(): string {
      if (this.trueLabel && this.value === this.trueValue)
        return this.trueLabel;
      else if (this.falseLabel && this.value === this.falseValue)
        return this.falseLabel;

      return this.label;
    },

    cClass(): string {
      return this.hideMarginTop ? "mt-0 ml-2 pt-0" : "ml-2 pt-0";
    },
  },
});
