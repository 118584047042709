
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ADialog",

  props: {
    title: {
      type: String as PropType<string>,
      required: true,
    },

    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    width: {
      type: [String, Number] as PropType<string | number | undefined>,
      default: undefined,
    },
  },
});
