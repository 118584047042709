
import Api from "@/lib/api";
import { Rq, Table } from "@/lib/amdt";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import IUser from "@/lib/interfaces/user";
import vDialogConfirm from "@/components/layout/v-dialog-confirm.vue";
import VAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ADate from "@/lib/a-date";
import ADataTable from "@/components/elements/a-data-table.vue";
import ACard from "@/components/elements/a-card.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";

export default Vue.extend({
  name: "UsersList",

  components: {
    vDialogConfirm,
    VAlertMessage,
    ABtn,
    ATextField,
    ASwitch,
    ADataTable,
    ACard,
    ASelectStatus,
  },

  props: {
    user: {
      type: Object as PropType<IUser>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      dialogConfirm: false,
      confirmCallback: () => null as unknown | undefined,

      total: 0,
      search: "",
      desativado: "0",

      options: {} as DataOptions,
      users: new Array<IUser>(),

      headers: [
        { text: "Usuário", value: "usuario" },
        { text: "E-mail", value: "email" },
        {
          text: "Dias Expiração",
          value: "cDiasExpiracaoSenha",
          sortable: false,
        },
        {
          text: "Data Expiração",
          value: "cDataProxExpiracaoSenha",
          sortable: false,
        },
        { text: "Dispositivo", value: "NULO", sortable: false },
        { text: "Data Criação", value: "cDataCriacao", sortable: false },
        { text: "Ativo", value: "desativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false },
      ],
    };
  },

  watch: {
    forceRefresh() {
      this.getUsers().then();
    },

    options: {
      deep: true,
      handler() {
        this.getUsers().then();
      },
    },
  },

  computed: {
    cUsers(): Record<string, string>[] {
      return this.users.map((user) => ({
        ...user,
        cDiasExpiracaoSenha:
          user.dias_expiracao_senha === "0" ? "N/A" : user.dias_expiracao_senha,
        cDataProxExpiracaoSenha: user.data_prox_expiracao_senha
          ? ADate.parse(user.data_prox_expiracao_senha).localeDateString
          : "N/A",
        cDataCriacao: ADate.parse(user.data_criacao).localeDateTimeString,
      }));
    },
  },

  methods: {
    async openUser(id: string) {
      const user = this.users.find((user) => user.id === id);
      this.$emit("update:user", { ...user });
    },

    async getUsers() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.read", {
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.users = rsp.getTable("usuario")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.dialogConfirm = false;
        this.loading = false;
      }
    },

    toggleDesativado(id: string, desativado: string) {
      this.dialogConfirm = true;

      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const ctx = this;

      this.confirmCallback = async function () {
        try {
          ctx.loading = true;
          ctx.ret_id = 1;
          ctx.ret_msg = "";

          const rq = new Rq("user.write");

          const tblUsuario = new Table("usuario");

          const obj = { id, desativado };
          tblUsuario.setColsFromObject(obj);
          tblUsuario.addRowsFromObject(obj);

          rq.addTable(tblUsuario);

          const rsp = await Api.request(rq);

          if (rsp.ret_id < 1) {
            ctx.ret_id = rsp.ret_id;
            ctx.ret_msg = rsp.ret_msg;
          } else {
            ctx.ret_msg = `Usuário ${
              desativado === "0" ? "ativado" : "desativado"
            } com sucesso`;
          }

          await ctx.getUsers();
        } catch (error) {
          console.error(error);
        } finally {
          ctx.confirmCallback = () => null;
          ctx.loading = false;
        }
      };
    },
  },
});
