
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import { required, arrayRequired, maxLength, email } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ADialog from "@/components/elements/a-dialog.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import IProfile from "@/lib/interfaces/profile";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import { numberPadding } from "@/lib/util";
import WorgError from "@/lib/worg-error";

export default Vue.extend({
  name: "NewUsuarioEmpresas",

  components: {
    vAlertMessage,
    ADialog,
    ABtn,
    ABtnDialogConfirm,
    AAutocomplete,
  },

  props: {
    userId: {
      type: String as PropType<string>,
      required: true,
    },

    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    profiles: {
      type: Array as PropType<IProfile[]>,
      required: true,
    },

    cSetoresRegioes: {
      type: Array as PropType<Record<string, string>[]>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      idSetorRegiao: new Array<string>(),
      idProfile: "",
    };
  },

  watch: {
    value() {
      this.form?.resetValidation();
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },
  },

  methods: {
    maxLength,
    arrayRequired,
    required,
    email,

    numberPadding,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("usuario_empresa.new_batch");

        const tblUsuarioEmpresa = new Table("usuario_empresa");

        const items = this.idSetorRegiao.map((item) => {
          const [id_setor, id_empresa] = item.split("-");
          return {
            id_setor,
            id_empresa,
            id_perfil: this.idProfile,
            id_usuario: this.userId,
          };
        });

        if (items.length === 0) return;

        tblUsuarioEmpresa.setColsFromObject(items[0]);
        tblUsuarioEmpresa.addRowsFromObject(...items);

        rq.addTable(tblUsuarioEmpresa);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form?.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Este usuário já possuí acesso a essa região";

          return;
        }

        this.ret_msg = "Acessos cadastrados com sucesso";

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.idSetorRegiao = [];
      this.idProfile = "";

      this.form?.resetValidation();
    },
  },
});
