
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ACheckbox from "@/components/elements/a-checkbox.vue";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import { Rq, Table } from "@/lib/amdt";
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { DataOptions } from "vuetify";
import ADataTable from "@/components/elements/a-data-table.vue";
import ASelectStatus from "@/components/elements/a-select-status.vue";
import ACard from "@/components/elements/a-card.vue";
import UsuarioEmpresa from "./usuario-empresa.vue";
import IUsuarioEmpresa from "@/lib/interfaces/usuario-empresa";
import IProfile from "@/lib/interfaces/profile";
import { numberPadding } from "@/lib/util";
import UsuarioEmpresaExtraPermissions from "./usuario-empresa-extra-permissions.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ASelect from "@/components/elements/a-select.vue";

export default Vue.extend({
  name: "UsuarioEmpresasList",

  components: {
    vAlertMessage,
    ABtn,
    ATextField,
    ACheckbox,
    ADataTable,
    ASelectStatus,
    ACard,
    UsuarioEmpresa,
    UsuarioEmpresaExtraPermissions,
    ASwitch,
    ASelect,
  },

  props: {
    userId: {
      type: String as PropType<string>,
      required: true,
    },

    forceRefresh: {
      type: Number as PropType<number>,
      required: true,
    },

    cSetoresRegioes: {
      type: Array as PropType<Record<string, string>[]>,
      required: true,
    },

    profiles: {
      type: Array as PropType<IProfile[]>,
      required: true,
    },
  },

  data() {
    return {
      pendingConnections: 0,
      ret_id: 1,
      ret_msg: "",
      desativado: "0",

      usuarioEmpresaDialog: false,
      showBatchActions: false,

      total: 0,
      search: "",

      options: {} as DataOptions,

      usuarioEmpresas: new Array<IUsuarioEmpresa>(),
      usuarioEmpresa: undefined as IUsuarioEmpresa | undefined,

      headers: [
        { text: "Selecionar", value: "checkbox", sortable: false },
        { text: "Setor", value: "cSetor", sortable: false },
        { text: "Região", value: "cRegiao", sortable: false },
        { text: "Perfil", value: "cPerfil", sortable: false },
        { text: "Padrão", value: "cPadrao", sortable: false },
        { text: "Status", value: "cDesativado", sortable: false },
        { text: "Ações", value: "actions", sortable: false, right: "right" },
      ],

      selected: [] as string[],
      idProfile: "",
    };
  },

  watch: {
    userId() {
      this.getUsuarioEmpresas().then();
    },

    forceRefresh() {
      this.getUsuarioEmpresas().then();
    },

    options: {
      deep: true,
      handler() {
        this.getUsuarioEmpresas().then();
      },
    },
  },

  computed: {
    cUsuarioEmpresas(): Array<Record<string, unknown>> {
      return this.usuarioEmpresas.map((usuarioEmpresa) => {
        const setorRegiao = this.cSetoresRegioes.find(
          (item) =>
            item.id_setor == usuarioEmpresa.id_setor &&
            item.id_regiao == usuarioEmpresa.id_empresa
        );
        const cSetor = setorRegiao
          ? `${numberPadding(setorRegiao.id_setor)} - ${setorRegiao.nome_setor}`
          : usuarioEmpresa.id_setor;

        const cRegiao = setorRegiao
          ? `${numberPadding(setorRegiao.id_regiao)} - ${
              setorRegiao.nome_regiao
            }`
          : usuarioEmpresa.id_empresa;

        return {
          ...usuarioEmpresa,

          cSetor,

          cRegiao,

          cPerfil:
            this.profiles.find(
              (profile) => profile.id === usuarioEmpresa.id_perfil
            )?.nome ?? usuarioEmpresa.id_perfil,

          cPadrao: usuarioEmpresa.padrao === "1" ? "Sim" : "Não",

          cDesativado: usuarioEmpresa.desativado === "0" ? "Ativo" : "Inativo",
        };
      });
    },

    loading: {
      get(): boolean {
        return this.pendingConnections > 0;
      },
      set(val: boolean) {
        this.pendingConnections += val ? 1 : -1;
      },
    },

    cIndeterminated(): boolean {
      return (
        this.selected.length > 0 &&
        this.selected.length !== this.usuarioEmpresas.length
      );
    },

    cAllSelected(): boolean {
      return this.selected.length === this.usuarioEmpresas.length;
    },
  },

  methods: {
    openUsuarioEmpresa(id: string) {
      const usuarioEmpresa = this.usuarioEmpresas.find(
        (usuarioEmpresa) => usuarioEmpresa.id === id
      );

      this.usuarioEmpresa = { ...usuarioEmpresa } as IUsuarioEmpresa;

      this.usuarioEmpresaDialog = true;
    },

    toggleSelection(id: string) {
      const idx = this.selected.findIndex((item) => item === id);
      if (idx > -1) this.selected.splice(idx, 1);
      else this.selected.push(id);
    },

    toggleSelectAll(value: boolean) {
      this.selected = value ? this.usuarioEmpresas.map((item) => item.id) : [];
    },

    async setProfile() {
      try {
        this.loading = true;

        const items = this.selected.map((id) => ({
          id,
          id_perfil: this.idProfile,
        }));

        if (items.length == 0) return;

        const rq = new Rq("usuario_empresa.update-profiles");
        const tbl = new Table("usuario_empresa");
        tbl.setColsFromObject(items[0]);
        tbl.addRowsFromObject(...items);
        rq.addTable(tbl);

        const response = await Api.request(rq);
        if (response.ret_id < 1) {
          this.ret_id = -1;
          this.ret_msg = "Não foi possível atualizar";
          return;
        }

        this.ret_id = 1;
        this.ret_msg = "Perfis atualizados com sucesso";
        this.getUsuarioEmpresas().then();
      } catch (error) {
        console.log(error);
      } finally {
        this.loading = false;
      }
    },

    async getUsuarioEmpresas() {
      try {
        if (!this.userId) {
          this.usuarioEmpresas = [];
          return;
        }

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("usuario_empresa.read", {
          idUsuario: this.userId,
          search: this.search,
          desativado: this.desativado,
          itemsPerPage: this.options.itemsPerPage.toString(),
          page: this.options.page.toString(),
          sortBy: this.options.sortBy[0] ?? "id",
          sortOrder: this.options.sortDesc[0] ? "DESC" : "ASC",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.total = Number(rsp.params["total"]);
        this.options.itemsPerPage = Number(rsp.params["itemsPerPage"]);
        this.options.page = Number(rsp.params["page"]);

        this.usuarioEmpresas =
          rsp.getTable("usuario_empresa")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
