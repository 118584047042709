
import Vue from "vue";
import UsersList from "@/components/subviews/users/users-list.vue";
import UsersMaintenance from "@/components/subviews/users/users-maintenance.vue";
import IUser from "@/lib/interfaces/user";

export default Vue.extend({
  name: "Users",

  components: { UsersList, UsersMaintenance },

  data() {
    return {
      ret_id: 1,
      ret_msg: "",

      refresh: 0,

      currentTab: 0,

      user: {} as IUser,
    };
  },
});
