
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import { required, maxLength, email } from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ADialog from "@/components/elements/a-dialog.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import IUsuarioEmpresa from "@/lib/interfaces/usuario-empresa";
import IProfile from "@/lib/interfaces/profile";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import { numberPadding } from "@/lib/util";

export default Vue.extend({
  name: "UsuarioEmpresa",

  components: {
    vAlertMessage,
    ADialog,
    ABtn,
    ATextField,
    ASwitch,
    ABtnDialogConfirm,
    AAutocomplete,
  },

  props: {
    userId: {
      type: String as PropType<string>,
      required: true,
    },

    usuarioEmpresa: {
      type: Object as PropType<IUsuarioEmpresa | undefined>,
    },

    value: {
      type: Boolean as PropType<boolean>,
      required: true,
    },

    cSetoresRegioes: {
      type: Array as PropType<Record<string, string>[]>,
      required: true,
    },

    profiles: {
      type: Array as PropType<IProfile[]>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataUsuarioEmpresa: {
        id: "",
        id_usuario: "",
        id_perfil: "",
        id_setor: "",
        id_empresa: "",
        padrao: "0",
        desativado: "0",
      } as IUsuarioEmpresa,

      idSetorRegiao: "",
    };
  },

  watch: {
    value() {
      if (!this.value) this.$emit("update:usuario-empresa", undefined);

      this.dataUsuarioEmpresa = this.usuarioEmpresa
        ? { ...this.usuarioEmpresa }
        : {
            id: "",
            id_usuario: "",
            id_perfil: "",
            id_setor: "",
            id_empresa: "",
            padrao: "0",
            desativado: "0",
          };

      if (this.usuarioEmpresa)
        this.idSetorRegiao = `${this.usuarioEmpresa.id_setor}-${this.usuarioEmpresa.id_empresa}`;

      this.form?.resetValidation();
    },

    idSetorRegiao() {
      const [id_setor, id_regiao] = this.idSetorRegiao.split("-");
      this.dataUsuarioEmpresa.id_setor = id_setor;
      this.dataUsuarioEmpresa.id_empresa = id_regiao;
    },
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cTextSave(): string {
      return this.dataUsuarioEmpresa.id ? "Alterar" : "Salvar";
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },
  },

  methods: {
    maxLength,
    required,
    email,

    numberPadding,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("usuario_empresa.write");

        const tblUsuarioEmpresa = new Table("usuario_empresa");

        const obj = {
          ...this.dataUsuarioEmpresa,
          id: this.dataUsuarioEmpresa.id || "default",
          id_usuario: this.userId,
        };
        tblUsuarioEmpresa.setColsFromObject(obj);
        tblUsuarioEmpresa.addRowsFromObject(obj);

        rq.addTable(tblUsuarioEmpresa);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form?.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Este usuário já possuí acesso a essa região";

          return;
        }

        this.ret_msg = `Acesso ${
          this.dataUsuarioEmpresa.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataUsuarioEmpresa.id = rsp.ret_id.toString();

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataUsuarioEmpresa = {
        id: "",
        id_usuario: this.userId,
        id_perfil: "",
        id_setor: "",
        id_empresa: "",
        padrao: "0",
        desativado: "0",
      };

      this.idSetorRegiao = "";

      this.form?.resetValidation();
    },
  },
});
