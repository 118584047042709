
import Api from "@/lib/api";
import Vue, { PropType } from "vue";
import {
  required,
  maxLength,
  minLength,
  passwordMatch,
  email,
} from "@/lib/validations";
import VForm from "@/lib/types/v-form";
import { Rq, Table } from "@/lib/amdt";
import WorgError from "@/lib/worg-error";
import IUser from "@/lib/interfaces/user";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ASwitch from "@/components/elements/a-switch.vue";
import ASelect from "@/components/elements/a-select.vue";
import ACard from "@/components/elements/a-card.vue";
import ATextFieldPassword from "@/components/elements/a-text-field-password.vue";
// import UsuarioEmpresa from "./maintenance/usuario-empresa.vue";
import UsuarioEmpresasList from "./maintenance/usuario-empresas-list.vue";
import NewUsuarioEmpresas from "./maintenance/new-usuario-empresas.vue";
import { numberPadding } from "@/lib/util";
import IProfile from "@/lib/interfaces/profile";

export default Vue.extend({
  name: "UsersMaintenance",

  components: {
    vAlertMessage,
    ABtnDialogConfirm,
    ATextField,
    ABtn,
    ASwitch,
    ASelect,
    ACard,
    ATextFieldPassword,
    // UsuarioEmpresa,
    UsuarioEmpresasList,
    NewUsuarioEmpresas,
  },

  props: {
    user: {
      type: Object as PropType<IUser>,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      dataUser: {
        id: "",
        usuario: "",
        email: "",
        senha: "",
        dispositivo: "0",
        data_prox_expiracao_senha: "",
        dias_expiracao_senha: "0",
        desativado: "0",
        data_criacao: "",
      } as IUser,

      senhaConfirmacao: "",

      diasExpiracaoSenha: [
        { value: "0", text: "Nunca" },
        { value: "5", text: "5 Dias" },
        { value: "15", text: "15 Dias" },
        { value: "30", text: "30 Dias" },
      ],

      setoresRegioes: new Array<{
        id_setor: string;
        id_regiao: string;
        nome_setor: string;
        nome_regiao: string;
      }>(),
      profiles: new Array<IProfile>(),

      usuarioEmpresaDialog: false,
      forceRefreshUsuarioEmpresas: 0,
      newUsuarioEmpresasDialog: false,
    };
  },

  watch: {
    user: {
      deep: true,
      handler() {
        this.ret_id = 1;
        this.ret_msg = "";
        this.dataUser = { ...this.user };
        this.senhaConfirmacao = "";
      },
    },
  },

  computed: {
    cTextSave(): string {
      return this.dataUser.id ? "Alterar" : "Salvar";
    },

    cValidaSenha(): Array<(value: string) => string | boolean> {
      if (!this.dataUser.id) {
        return [required, minLength(8), maxLength(40)];
      }

      return this.dataUser.senha ? [minLength(8), maxLength(40)] : [];
    },

    cValidaSenhaConfirmacao(): Array<(value: string) => string | boolean> {
      return this.dataUser.senha
        ? [required, passwordMatch(this.dataUser.senha, true)]
        : [passwordMatch(this.dataUser.senha)];
    },

    cValidaEmail(): Array<(value: string) => string | boolean> {
      return this.dataUser.email ? [maxLength(40), email] : [];
    },

    form(): VForm {
      return this.$refs.form as VForm;
    },

    cSetoresRegioes(): Record<string, string>[] {
      return this.setoresRegioes.map((item) => ({
        ...item,
        key: `${item.id_setor}-${item.id_regiao}`,
        text: `${numberPadding(item.id_setor)} - ${
          item.nome_setor
        }: ${numberPadding(item.id_regiao)} - ${item.nome_regiao}`,
      }));
    },
  },

  created() {
    this.getSetoresRegioes().then();
    this.getProfiles().then();
  },

  methods: {
    required,
    maxLength,
    passwordMatch,
    numberPadding,

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("user.write");

        const tblUser = new Table("usuario");

        const obj = {
          ...this.dataUser,
          id: this.dataUser.id || "default",
        };
        tblUser.setColsFromObject(obj);
        tblUser.addRowsFromObject(obj);

        rq.addTable(tblUser);

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          const errorName = WorgError.getErrorByCode(this.ret_id).name;
          if (errorName === "VldError") this.form.validate();
          else if (errorName === "ErrDupEntry")
            this.ret_msg = "Já existe um usuário com esse nome";

          return;
        }

        this.ret_msg = `Usuário ${
          this.dataUser.id ? "atualizado" : "cadastrado"
        } com sucesso`;

        this.dataUser.id = rsp.ret_id.toString();
        this.dataUser.senha = "";
        this.senhaConfirmacao = "";

        this.$emit("updated");
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    clearForm() {
      this.dataUser = {
        id: "",
        usuario: "",
        email: "",
        senha: "",
        dispositivo: "0",
        data_prox_expiracao_senha: "",
        dias_expiracao_senha: "0",
        desativado: "0",
        data_criacao: "",
      };
      this.senhaConfirmacao = "";

      this.form.resetValidation();
    },

    async getSetoresRegioes() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas_setor_regiao.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.setoresRegioes =
          rsp.getTable("setor_regiao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getProfiles() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("profile.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.profiles = rsp.getTable("perfil")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
